"use client"
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";

export default function GoogleAnalyticsDefer({ gaId }: { gaId: string }) {
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        setIsShow(true);
    }, []);

    const GoogleAnalytics = dynamic(
        () => import('@next/third-parties/google').then((mod) => mod.GoogleAnalytics),
        { ssr: false, loading: () => null }
    );

    return (
        <>
            {isShow && <GoogleAnalytics gaId={gaId} />}
        </>
    );
}
