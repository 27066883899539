export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Fisch Robolox",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://game.fischroblox.fun/Robbie_%20Super%20Fishing%20(by%20GB%20Games)_%20Play%20Online%20For%20Free%20On%20Playhop.html",
    domain: "fischroblox.org",
    gaId: "G-TZ43PEWC30",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
